import { UserDataService } from 'src/app/services/user-data.service';
import { NavigationService } from './../../services/navigation.service';
import { Component, OnInit, HostBinding, ApplicationRef, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {TweenMax, Linear} from "gsap";
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { environment } from 'src/environments/environment';
import { NavigationService as NavigationServiceShared} from './../../shared/navigation/navigation.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { TimelineMax, Power1 } from 'gsap';
import { GameService } from 'src/app/services/game.service';
import { JaugeComponent } from '../page-game/widgets/jauge/jauge.component';

@Component({
  selector: 'app-gameover2',
  templateUrl: './gameover2.component.html',
  styleUrls: ['./gameover2.component.scss'],
})
export class Gameover2Component implements AfterViewInit {
	
	// @HostBinding('@animations') private animations;
	
	score: number;
	
	desc:string;
	
	colorTest:any;
	contentTest:any;
	math = Math;
	private indexScenario: number;
	private jauge1Value: number;
	@ViewChild('jauge1') jauge1: JaugeComponent;
	@ViewChild('jaugePanel') jaugePanel: ElementRef;
	@ViewChild('scorePanel') scorePanel: ElementRef;
	

  constructor(
		public ds:DataService,
		private wordings: WordingsService,
		private modalService:ModalsService,
		private timeout:TimeoutService,
		private navigation:NavigationService,
		private userData:UserDataService,
		private navShared:NavigationServiceShared,
		private animate:TweenAnimateService,
		private app:ApplicationRef,
		private game: GameService,
	) { }

	ngAfterViewInit() {
		this.game.hideBackgroundWithFade();
		this.indexScenario = this.userData.data.indexScenario;
		
		let params:any = this.navShared.getParams('gameover2');
		if(!params){
			params = {
				jaugeA_start: 59,
				jaugeA_end: 29,
				jaugeB_start: 39,
				jaugeB_end: 0,
			};
		}
		this.desc = 'ui.gameover.chap' + this.indexScenario + '.' + this.userData.data.dataActions['gameovertheme'];
		
		
		this.jauge1Value = this.userData.data.dataActions["lastJauge1"];
		this.jauge1.setValue(this.jauge1Value, 0);
		
		let targetScore = this.userData.getCurrentTotalScore();
		let startScore = targetScore + this.userData.getPointPerPercent() * this.jauge1Value;

		//this.animate.reset();
		let timeline = new TimelineMax();
		timeline.to(this.jaugePanel.nativeElement,
			{
				opacity: 1,
				duration: 0.4,
				onUpdate: () => {
					this.jauge1.setValue(this.jauge1Value, 0);
				}
			},
			"+=1");
		timeline.to(this.scorePanel.nativeElement,
			{
				opacity: 1,
				duration: 0.4,
			},
			"-=0.2");
		timeline.to(
			this, {
				jauge1Value: 0,
				ease: Power1.easeInOut,
				duration: 2,
				onUpdate: () => {
					this.jauge1.setValue(this.jauge1Value, 0);
				}
			}
		);
		timeline.fromTo(this, {score: targetScore + startScore}, {score: targetScore, duration: 2}, '-=1');
		
	}
	
	
	clickRestart():void
	{
		this.userData.loadCheckpoint();
		this.userData.data.ngGameover++;
		this.userData.setCheckpoint();
		this.userData.save();
		this.modalService.open('game');
	}

}
