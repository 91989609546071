import { Component, OnInit, AfterViewInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { environment } from 'src/environments/environment';
import { WordingsService } from 'src/app/services/wordings.service';
import { MesureImpactStateService } from "src/app/services/mesure-impact-state.service";
import { gameoverAnimation } from 'src/app/animations/home.animations';
//import { type } from 'os';
declare var dmi: any;

type FormFieldData = ComboFormData | TextFormData | ComputedFormData;

export class FormElementDescriptor {
  public displayName: string;
  public configName: string;
  public type: string;
  public options: string[];
}

export class ComboFormData {
  public displayName: string;
  public configName: string;
  public type: string = "combo";
  public options: (string[] | Object);
}

export class TextFormData {
  public displayName: string;
  public configName: string;
  public type: string = "text";
  public placeholderText: string;
}

export class ComputedFormData {
	public configName: string;
	public type: string = "computed";
	public value: string;
	public displayName: string = "";
}

@Component({
	selector: 'app-score-send',
	templateUrl: './score-send.component.html',
	styleUrls: ['./score-send.component.scss'],
  animations: [gameoverAnimation],
})
export class ScoreSendComponent implements OnInit, AfterViewInit {

  @HostBinding('@animations') private animations;

	nickname: string;
	score: number;

	mesureImpactState: number = 0;
	skipped: boolean = false;
	choiceDisplayed: boolean = false;
  selectors: FormFieldData[];
  selectedOptions: string[] = [];
  formGroups: number[];

	formChoiceContainer: HTMLElement;
	scoreSendContainer: HTMLElement;
	bonusPointsContainer: HTMLElement;

	constructor(
		private modalService: ModalsService,
		private api: ApiService,
		public ds: DataService,
		private wordings: WordingsService,
		private userData: UserDataService,
		private impactService: MesureImpactStateService,
	) { }

	ngOnInit() {
		// dmi.resetCookies();
		this.impactService.currentMessage.subscribe(message => {
			this.mesureImpactState = message;
			this.choiceDisplayed = this.ds.versionAllowedForm && this.mesureImpactState == 0 && !dmi.userIsFinished();
			if (dmi.userIsFinished()) this.userData.addMIScore(this.ds.dmiBonusPoints);
			this.score = this.userData.getCurrentScoreWithoutMI();

      this.updateSelectorsList();
      while(this.selectedOptions.length < this.selectors.length)
      {
        let selector = this.selectors[this.selectedOptions.length];
        if(selector.type === 'text')
        this.selectedOptions.push('');
        else
        {
          let options = (selector as ComboFormData).options;
          let defaultOption = options[Object.keys(options)[0]];
          if(typeof(defaultOption) == 'string')
          this.selectedOptions.push(defaultOption as string);
          else this.selectedOptions.push(Object.keys(options)[0]);
        }
      }
      this.userData.save();
		});
	}

	ngAfterViewInit() {
    this.scoreSendContainer = document.getElementById("score_display");
		this.scoreSendContainer.style.display = "block";

	}

  updateSelectorsList() {
    this.formGroups = [];
    this.selectors = [];

    for(let i = 0; i < this.ds.leaderboard_form.length; i++)
    {
      this.formGroups.push(0);
      this.generateFormFromConfig(this.ds.leaderboard_form[i], this.selectedOptions, this.selectors, this.formGroups);
    }
  }

  // Generate a form from the config specified in the company specific data at data/company/[companyUID]/leaderboard_form.txt
	// a default_leaderboard_form.json is used when none is present
	// Example of forms are present in the DOC folder of the repository
  //
  generateFormFromConfig(currentNode: FormFieldData, selectedOptions: string[], selectors: FormFieldData[], formGroups: number[])
  {
    let toAdd: FormElementDescriptor = {
      displayName: currentNode.displayName,
      configName: currentNode.configName,
      type: currentNode.type,
      options: []
    }

    if(currentNode.type == 'combo')
    {
      const options = (currentNode as ComboFormData).options;
      if(Array.isArray(options))
      {
        for(let option of (options as string[]))
        {
          toAdd.options.push(option);
        }
      }
      else if(typeof(options) == 'object')
      {
        for(let key in options as Object)
        {
          toAdd.options.push(key);
        }
      }
    }
    formGroups[formGroups.length - 1]++;
    this.selectors.push(toAdd);
    const selectorIndex = this.selectors.length - 1;

    if(this.selectedOptions !== undefined && this.selectedOptions.length > selectorIndex && this.selectedOptions[selectorIndex] != null)
    {
      switch(currentNode.type)
      {
        case "combo":
					let formData = (currentNode as ComboFormData);

					if(formData != undefined)
					{
						let selectedOption = formData.options[this.selectedOptions[selectorIndex]];
						if(selectedOption == undefined)
						{
							selectedOption = this.selectedOptions[selectorIndex];
						}
						if(typeof(selectedOption) == 'string')
						{

						}
						else if(Array.isArray(selectedOption))
						{
							let options = selectedOption as FormFieldData[];
							for(let key in options)
							{
								this.generateFormFromConfig(options[key], selectedOptions, selectors, formGroups);
							}
						}
						else
						{
							this.generateFormFromConfig(selectedOption as FormFieldData, selectedOptions, selectors, formGroups);
						}
					}
				break;
				default:
				break;
      }
    }
    else
    {
      switch(currentNode.type)
      {
        case "combo":
          const formData = currentNode as ComboFormData;
          let childNode = formData.options[Object.keys(formData.options)[0]];
          if(Array.isArray(childNode))
          {
            for(let node of childNode as FormFieldData[])
            {
              if(typeof(node) !== 'string')
              {
                this.generateFormFromConfig(node, selectedOptions, selectors, formGroups);
              }
            }
          }
          else
          {
            if(typeof(childNode) !== 'string')
            {
              this.generateFormFromConfig(childNode, selectedOptions, selectors, formGroups);
            }
          }
          break;
          case "text":

          break;
          default:

          break;

      }
    }
  }


	valid(): void {
    let score: number = this.userData.data.miScore;
    if(environment.debug && score == 0)
    {
      score = 255;
    }
    score = Math.round(score);
    let uscores = this.userData.data.scores;
    let data = {};
    for(let i in this.selectedOptions)
    {
      if(this.selectors[i].configName !== undefined)
      {
        data[this.selectors[i].configName] = this.selectedOptions[i];
      }
    }
    for(let selector of this.ds.leaderboard_form)
    {
      if(selector.type == 'computed')
      {
        let value = (selector as ComputedFormData).value;
        var regExp = /\[[^\]]*\]/g;
        let toParse = value;
        let match = regExp.exec(toParse);
        let cursor = 0;
        let result = "";
        for(; match != undefined && match != null;)
        {
          let valueName = match[0].substring(1, match[0].length - 1);
          result += toParse.substring(cursor, match.index) + data[valueName];
          cursor = match.index + match[0].length;
          match = regExp.exec(toParse);
        }
      }
    }
    /* a revoir par raport à la propièté mi_bonus
    if(dmi.userIsFinished())
		{
			this.userData.data.scores.mi_bonus = 1200;
		}
		else
		{
			this.userData.data.scores.mi_bonus = 0;
		}
    */
    this.userData.save();
		this.api.saveScore(data['nickname'], this.ds.uid, JSON.stringify(data), "")
			.subscribe(resp => {
        let playerRank: string = '' + resp.data.playerRank;
        this.ds.leaderboard_user = this.ds.getLeaderboardHero(resp.data.leaderboard, playerRank);
        //this.ds.leaderboard = resp.data.leaderboard;
        this.ds.leaderboard = this.ds.leaderboard_user;

				// #TODO : verifier que le playerscoreId est bien get et sauvegarde
				this.userData.playerscoreId = resp.data.scoreId;
				this.modalService.open('leaderboard');
        //this.userData.resetAll();
        this.userData.save();
			});
	}

	/*launchForm(): void {
		this.modalService.open("mesure-impact");
	}

	skipForm(): void {
		this.formChoiceContainer.style.display = "none";
		this.scoreSendContainer.style.display = "block";
	}*/

  formChoiceHidden(): string {
    let isDisplayed = !this.ds.versionAllowedForm || this.mesureImpactState == 1 || this.skipped || dmi.userIsFinished();
    return isDisplayed || this.ds.uid === 'sncf' ? "block" : "none";
  }

  onValueChanged(event: any) {
    let index = 0;
		for(let i=0; i<event.lineIndex; i++)
		{
			index += this.formGroups[i];
		}
		index += event.elementIndex;
		let value = event.value;
		while(this.selectedOptions.length < this.selectors.length)
		{
			let selector = this.selectors[this.selectedOptions.length];
			if(selector.type === 'text')
				this.selectedOptions.push('');
			else
			{
				let options = (selector as ComboFormData).options;
				let defaultOption = options[Object.keys(options)[0]];
				if(typeof(defaultOption) == 'string')
					this.selectedOptions.push(defaultOption as string);
				else this.selectedOptions.push(Object.keys(options)[0]);
			}
		}
		this.selectedOptions[index] = value;
		if(this.selectors[index].type == 'combo')
		{
			for(let i=index+1; i<this.selectedOptions.length; i++)
			{
				this.selectedOptions[i] = null;
			}
			this.updateSelectorsList();
			for(let i=index+1; i < this.selectedOptions.length; i++)
			{
				let selector = this.selectors[i];
				let comboData = (selector as ComboFormData);
				if(comboData != undefined)
				{
					let options = comboData.options;
					this.selectedOptions[i] = options[Object.keys(options)[0]];
				}
			}
		}
	}

  getFormLineConfig(lineIndex: number): FormFieldData[]
	{
		let result: FormFieldData[] = [];
		let cursor = 0;
		for(let i=0; i<lineIndex; i++)
		{
			cursor += this.formGroups[i];
		}
		for(let i=0; i<this.formGroups[lineIndex]; i++)
		{
			result.push(this.selectors[cursor]);
			cursor++;
		}
		return result;
	}

  getSelectedOptions(lineIndex: number): string[]
	{
		let result: string[] = [];
		let cursor = 0;
		for(let i=0; i<lineIndex; i++)
		{
			cursor += this.formGroups[i];
		}
		for(let i=0; i<this.formGroups[lineIndex]; i++)
		{
			result.push(this.selectedOptions[cursor]);
			cursor++;
		}
		return result;
	}
}
