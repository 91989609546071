import { WordingsService } from './../services/wordings.service';
import { MesureImpactStateService } from './../services/mesure-impact-state.service';
import { UserDataService } from './../services/user-data.service';
import { DataService } from './../services/data.service';
import { ApiService } from './../services/api.service';
import { ModalsService } from './../shared/navigation/modals.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { gameoverAnimation } from '../animations/home.animations';
declare var dmi: any;
declare var pipwerks;

type FormFieldData = ComboFormData | TextFormData;

export class FormElementDescriptor {
	public displayName: any;
	public configName: string;
	public type: string;
	public options: string[];
}

export class ComboFormData {
	public displayName: any;
	public configName: string;
	public type: string = "combo";
	public options: (string[] | Object);

}

export class TextFormData {
	public displayName: any;
	public configName: string;
	public type: string = "text";
	public placeholderText: string;
}

@Component({
  selector: 'app-form-line',
  templateUrl: './form-line.component.html',
  styleUrls: ['./form-line.component.css'],
  animations: [gameoverAnimation],
})
export class FormLineComponent implements OnInit {
  @Input() config: FormFieldData[];
  @Input() selectedOptions: string[] = [];
  @Input() index: number;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalService: ModalsService,
    private api: ApiService,
    public ds: DataService,
    private userData: UserDataService,
    private impactService: MesureImpactStateService,
    private wordings: WordingsService,
  ) { }

  ngOnInit(): void {

  }

  onValueChanged(value: string, elementIndex: number){
    this.valueChanged.emit({lineIndex: this.index, elementIndex: elementIndex, value: value});
  }
}
