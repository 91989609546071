import { Component, OnInit, HostBinding } from '@angular/core';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { leaderboardAnimation } from 'src/app/animations/leaderboard.animations';
import { DataService } from 'src/app/services/data.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-quiz-leaderboard',
  templateUrl: './quiz-leaderboard.component.html',
	styleUrls: ['./quiz-leaderboard.component.scss'],
})
export class QuizLeaderboardComponent implements OnInit {
	public chapter: number;
	public score: number;
	public isLastLeaderboard: boolean;
	
  constructor(
	  	private wordings: WordingsService,
		private modalService:ModalsService,
		private routerEvent:RouterEventService,
		public ds:DataService,
		public userData: UserDataService,
	) { }

  	ngOnInit() {
		  this.chapter = this.userData.data.indexScenario;
		  if(this.chapter >= this.ds.scenarios.length - 1)
		  {
			this.isLastLeaderboard = true;
		  	this.chapter = 0;
		  }
		  this.score = this.userData.getCurrentTotalScore();
  	}
  
	valid():void
	{
		this.userData.incrChapter();
		console.log(this.userData.data.indexScenario + " == " + this.ds.scenarios.length);
		if(this.userData.data.indexScenario + 1 == this.ds.scenarios.length)
		{
			this.modalService.open('game');
		}
		else if(this.userData.data.indexScenario + 1 > this.ds.scenarios.length) // last chapter is outro, so quiz instead of chapter end
		{
			this.modalService.open('scoresend');
		}
		else
		{
			this.modalService.open('chapter-start');
		}
	}
}
