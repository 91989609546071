import { Component, OnInit, Input, ViewChild, ElementRef, NgZone, Output, EventEmitter } from '@angular/core';
import { AnimData, GameAnimationsService } from 'src/app/services/game-animations.service';
import {TweenMax, TimelineMax} from "gsap";
import { TextLineData } from 'src/app/model/callbackinfo.interface';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-notebook',
  templateUrl: './notebook.component.html',
  styleUrls: ['./notebook.component.scss']
})
export class NotebookComponent implements OnInit {
  private currentVisibility: boolean = false;
  private targetVisibility: boolean = false;
  private  displayedLeftPage: string;
  private displayedRightPage: string;
  private blockInput: boolean = false;
  private styleBG = {};
  private leftPageTitle: boolean = false;
  
  @ViewChild('mainElement') mainElement:ElementRef;
  @ViewChild('button') button:ElementRef;
  @Output('nextClicked') nextClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(private animations: GameAnimationsService, private ngZone: NgZone, private wordings: WordingsService) {
  }

  ngOnInit() {
  }

  showNotebook(textLines: TextLineData[])
  {
    if(this.targetVisibility)
      this.animations.pushAnimation('notebook disappear', this.getDisappearAnimation(), 0, 1);
    this.animations.pushAnimation('notebook appear', this.getAppearAnimation(textLines[0].text, textLines[1], textLines[2].text), 2, 9);
    this.targetVisibility = true;
  }

  hideNotebook()
  {
    if(this.targetVisibility)
      this.animations.pushAnimation('notebook disappear', this.getDisappearAnimation(), 0, 1);
      this.targetVisibility = false;
      this.button.nativeElement.style.pointerEvents = "none";
  }

  getAppearAnimation(background: string, leftPage: TextLineData, rightPage: string): any
  {
    let timeline = new TimelineMax();
    timeline.call(() => this.ngZone.run(() => {
      this.styleBG['background-image'] = 'url("assets/dynamic/decors/' + background + '.png")';
    }));
    timeline.to(this.mainElement.nativeElement, 0.8, 
    {
      opacity: 1,
      'pointer-events': 'all', 
      onStart:() => {
        this.ngZone.run(() => {
          this.displayedLeftPage = leftPage.text;
          this.leftPageTitle = leftPage.parameters.includes("Titre");
          this.displayedRightPage = rightPage;
          this.blockInput = true;
        });
      }
    });
    timeline.call(() => {
      this.ngZone.run(() => {
        this.currentVisibility = true;
        this.button.nativeElement.style.pointerEvents = "all";
      });
    });

    return timeline;
  }

  getDisappearAnimation()
  {
    let timeline = new TimelineMax();
    timeline.to(this.mainElement.nativeElement, 0.8, {opacity: 0, 'pointer-events': 'none', onComplete: () => {
      this.ngZone.run(() => {
        this.currentVisibility = false;
        this.blockInput = false;
      });
    }});
    return timeline;
  }

  onNextClick() {
    this.nextClicked.emit();
    this.button.nativeElement.style.pointerEvents = "none";
  }
}
