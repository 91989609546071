import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, NgZone } from '@angular/core';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import { TimelineMax } from 'gsap';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-ellipse',
  templateUrl: './ellipse.component.html',
  styleUrls: ['./ellipse.component.scss']
})
export class EllipseComponent implements OnInit {

  @ViewChild('mainElement') mainElement: ElementRef;
  @Output() nextClicked: EventEmitter<void> = new EventEmitter<void>();
  private text: string;
  private currentVisibility: boolean;
  private targetVisibility: boolean;

  constructor(private animations: GameAnimationsService, private wordings: WordingsService, private ngZone: NgZone) { }

  ngOnInit() {
  }

  showEllipse(text: string)
  {
    if(!this.targetVisibility)
    {
      this.animations.pushAnimation('appear ellipse', this.getAppearAnimation(text), 2, 20);
      this.targetVisibility = true;
    }
  }

  hideEllipse()
  {
    if(this.targetVisibility)
    {
      this.animations.pushAnimation('disappear ellipse', this.getDisappearAnimation(), 0, 0);
      this.targetVisibility = false;
    }
  }

  getAppearAnimation(text: string): any
  {
    let timeline = new TimelineMax();
    timeline.call(() => this.ngZone.run(() => {
      this.text = text;
      this.currentVisibility = true;
    }));
    timeline.to(this.mainElement.nativeElement, 0.2, 
    {
      opacity: 1,
      'pointer-events': 'all',
    });
    return timeline;
  }

  getDisappearAnimation(): any
  {
    let timeline = new TimelineMax();
    timeline.to(this.mainElement.nativeElement, 0.2, 
    {
      opacity: 0,
      'pointer-events': 'none',
      onComplete: () => {
        this.currentVisibility = false;
      }});
    timeline.call(() => this.ngZone.run(() => {
      this.currentVisibility = false;
    }));
    return timeline;
  }

  onNextClick(): any
  {
    this.nextClicked.emit();
  }
}
