import { DataService } from './data.service';
import { CallbackInfo, createMock as createMockInfo } from './../model/callbackinfo.interface';
import { GameMode, NodeType, CallbackType } from '../model/types';
import { TraceService } from './../shared/debug/trace.service';
import { GameNodeTarget } from '../model/gamenodetarget.interface';
import { GameNode, Graph } from './../model/gamenode.interface';
import { Injectable, ElementRef, AfterViewInit, OnInit, AfterContentInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WordingsService } from './wordings.service';
import { TimelineMax, Power2 } from 'gsap';
import { UserDataService } from './user-data.service';
import { GameAnimationsService } from './game-animations.service';

// Service that handles the scenario data
@Injectable({
  providedIn: 'root'
})
export class GameService {
	
	private graph:Graph;
	public bg1: ElementRef;
	public bg2: ElementRef;
	public bgOverlay: ElementRef;

	// private mode:string;	//tentative de le set en mode global, mais ptet gestion recursive nécéssaire
	
	callback:(output:CallbackInfo, node:GameNode, level:number)=>void;
	chapterEndCallback:() => void;
	
	
	constructor(
		private trace:TraceService,
		private wordings: WordingsService,
		private userData: UserDataService,
		private gameAnimations: GameAnimationsService,
	) { }

	initElements(bg1: ElementRef, bg2: ElementRef, bgOverlay: ElementRef)
	{
		this.bg1 = bg1;
		this.bg2 = bg2;
		this.bgOverlay = bgOverlay;
	}

	startChapter(graph:Graph, nodeId:string):void
	{
		this.graph = graph;
		// this.mode = '';
		
		//calculate additional props
		
		for(let k in this.graph){
			let node = this.graph[k];
			node.id = k;
			
			if(environment.debug){
				for(let kk in node.targets){
					let nodeTarget:GameNodeTarget = node.targets[kk];
					for(let kkk in nodeTarget.actions){
						let action:string = nodeTarget.actions[kkk];
						//if(action.substr(0, 6) == '$jauge') nodeTarget.actions[kkk] = "$jauge1+=13";
					}
				}
			}
		}
		let firstNode = this.graph[nodeId];
		this.rec(firstNode, null, 0);
	}
	
	
	private rec(node:GameNode, parentNode:GameNode, level:number)
	{
		let output:CallbackInfo = this.handleNode(node, parentNode, level);
		//console.log("node " + node.id);
		
		if(output.callback) this.callback(output, node, level);
		if (output.continue) this.followNodes(node, level);
		else
		{
			this.gameAnimations.startUpdateAnimation();
		}
	}
	
	// scenario nodes can contain metadata with the following syntax : [keyword1]line1|[keyword2][keyword3]line2
	// text lines are separated by '|' and can start with keywords enclosed in []
	// This function extracts the text parameters from a single line
	public extractTextParameters(text: string)
	{
		let result: string[] = [];
		let args: number[] = [];
		while(text.includes('['))
		{
			const startIndex = text.indexOf("[");
			const endIndex = text.indexOf(']', startIndex);
			let cutStr = '';
			if(startIndex > 0)
				cutStr += text.substring(0, startIndex);
			cutStr += text.substring(endIndex + 1, text.length);
			console.log(text.length);
			let parameter: string = text.substring(startIndex + 1, endIndex - startIndex);
			let splitArguments = parameter.split(" ");
			if(splitArguments.length > 0)
			{
				console.log(splitArguments);
				
				if(splitArguments.length > 0)
				{
					for(var i of splitArguments.slice(1))
					{
						args.push(parseFloat(i))
					}
				}
				result.push(splitArguments[0]);
			}
			else result.push(parameter);
			text = cutStr;
		}
		
		return {text: text, parameters: result, arguments: args}; 
	}
	
	
	//convert GameNode to CallbackInfo
	
	private handleNode(node:GameNode, parentNode:GameNode, level:number):CallbackInfo
	{
		let output:CallbackInfo = createMockInfo();
		output.continue = true;
		output.callback = false;
		output.nodeId = node.id;
		
		//todo remove this.mode
		/* 
		if(node.type == NodeType.Dialogue_Begin) this.mode = GameMode.Dialog;
		else if(node.type == NodeType.Dialogue_End) this.mode = '';
		else if(node.type == NodeType.Ellipse_Begin) this.mode = GameMode.Ellipse;
		else if(node.type == NodeType.Ellipse_End) this.mode = '';
		 */
		
		this.trace.tracerec('node : '+node.id+', '+node.type, level);
		
		//if choice
		// if(this.mode == GameMode.Dialog && node.talker){
		if(node.type == NodeType.DialogElement && node.talker){
			const rawText = this.wordings.translate(node.voiceFile);
			//console.log(rawText.split('|'));
			let linesData = [];
			// extract the different text lines (they are separated by '|')
			for(const line of rawText.split('|')) {
				linesData.push(this.extractTextParameters(line));
			}
			output.voiceFile = node.voiceFile;
			
			
			output.textLines = linesData;

			for(const textLine of output.textLines)
			{
				if(textLine.parameters.includes('Didascalie')){
					while(textLine.text.charAt(0) == ' ') textLine.text = textLine.text.substr(1);
				}
			}
			
			/* 
			if(environment.debug){
				let str = output.questionText;
				for(let i=0;i<10;i++) output.questionText += '\n' + str;
			}
			 */
			
			
			if(environment.debug) node.talker = node.talker.replace('content', 'neutre');
			let talkers:string[];
			
			if(['empty'].indexOf(node.talker) == -1) talkers = node.talker.split('|');
			else talkers = [];
			
			
			output.continue = false;
			output.callback = true;
			output.talkers = talkers;
			if (talkers && talkers.length > 0){
				let tab = talkers[0].split('_');
				output.talkerName = tab[0];
			}
			
			node.targets = node.targets.filter((obj:GameNodeTarget) => this.evalConditions(obj.conditions));
			let nodeTargets:GameNode[] = node.targets.map((obj:GameNodeTarget) => this.graph[obj.id]);
			nodeTargets = nodeTargets.filter((node:GameNode) => node.talker == '');
			
			output.actions = nodeTargets.map((obj:GameNode) => obj.targets[0].actions);
			
			output.answers = nodeTargets.map((obj:GameNode) => obj.voiceFile);
		}
		
		// if(this.mode == GameMode.Ellipse && node.type == NodeType.DialogElement){
		if(node.type == NodeType.DialogElement && node.talker == 'time'){
			
			output.continue = false;
			output.callback = true;
			
		}
		
		else if(node.type == NodeType.Background){
			
			output.callback = true;
			output.background = node.background;
			
		}
		
		else if(node.type == NodeType.End){
			
			this.chapterEndCallback();
			output.callback = false;
		}
		
		return output;
	}
	
	
	
	public followNodes(node:GameNode, level:number, selectedIndex:number = -1)
	{
		let len:number = node.targets ? node.targets.length : 0;
		let start=0; 
		let end = len;
		
		//answer selected
		if(selectedIndex != -1){
			start = selectedIndex;
			end = start+1;
		}
		//conditions
		else{
			
			for(let i=start;i<end;i++){
				
				let target:GameNodeTarget = node.targets[i];
				if(target.conditions) target.resultCondition = this.evalConditions(target.conditions);
				else target.resultCondition = true;
				
				if(target.resultCondition){
					start = i;
					end = start+1;
					break;
				}
			}
		}
		
		for(let i=start;i<end;i++){
			
			let target:GameNodeTarget = node.targets[i];
			if(target == null){
				//console.log('');
			}
			
			if(target.actions){
				this.execActions(target.actions);
			}
			
			
			let newNode:GameNode = this.graph[target.id];
			this.rec(newNode, node, level + 1);
			
		}
	}
	
	
	private execActions(actions:string[]):void
	{
		for(let k in actions){
			let action = actions[k];
			
			action = action.replace('$', 'this.userData.data.dataActions.');
			if(this.userData.data.dataActions === undefined)
				this.userData.data.dataActions = {};
			eval(action);
		}
		//console.log(this.dataActions);
	}
	
	private evalConditions(conditions:string[]):boolean
	{
		let output:boolean = true;
		
		for(let k in conditions){
			let cond = conditions[k];
			cond = cond.replace('$', 'this.userData.data.dataActions.');
			//console.log("Eval condition : " + cond);
			let result:boolean = eval(cond);
			if(!result){
				output = false;
				break;
			}
		}
		return output;
	}
	
	private currentBackground: string;
	private overlayVisible: boolean;
	private travellingTimeline = null;

	public startTravelling(startX: number, startY: number, startScale: number, targetX: number, targetY: number, targetScale: number, duration: number)
	{
		if(this.travellingTimeline != null)
			this.travellingTimeline.kill();
		this.travellingTimeline = new TimelineMax({repeat: 100, yoyo: true, paused: true});
		this.travellingTimeline.set(this.bg1.nativeElement, {
			scaleX: startScale,
			scaleY: startScale,
			xPercent: (startX - 50) * startScale,
			yPercent: (startY - 50) * startScale,
		});
		this.travellingTimeline.to(this.bg1.nativeElement, {
			duration: duration,
			ease: Power2.easeInOut,
			scaleX: targetScale,
			scaleY: targetScale,
			xPercent: (targetX - 50) * startScale,
			yPercent: (targetY - 50) * startScale,
		},);
	}

	public stopTravelling()
	{
		if(this.travellingTimeline != null)
		this.travellingTimeline.kill();
		this.travellingTimeline = null;
	}
	
	public setBackground(backgroundName: string, showOverlay: boolean = false) {
		const timeline = new TimelineMax();
		if(this.overlayVisible !== showOverlay) {
			//timeline.to(this.bgOverlay.nativeElement, {opacity: showOverlay ? 1:0, duration: 1});
			this.overlayVisible = showOverlay;
		}
		if(this.currentBackground !== backgroundName) {
			timeline.add(new TimelineMax()
				.set(this.bg2.nativeElement, 
					{
						opacity: 0,
						'background-image': 'url("assets/dynamic/decors/' + backgroundName + '.jpg',
						transform: 'translate(0, 0)'
					}
				)
				.set(this.bg1.nativeElement,
					{
						'background-image': 'url("assets/dynamic/decors/' + backgroundName + '.jpg',
						transform: 'translate(0, 0)',
						opacity: 1,
					}
				), 0
			);
			this.currentBackground = backgroundName;
		}
		if(this.travellingTimeline != null)
			timeline.call(() => {console.log("Play Travel Timeline"); this.travellingTimeline.play();})
		return timeline;
	}

	public setBackgroundWithFade(backgroundName: string, showOverlay: boolean = false): any {
		console.log("Background change anim : " + backgroundName);
		const timeline = new TimelineMax();
		if(this.overlayVisible !== showOverlay) {
			//timeline.to(this.bgOverlay.nativeElement, {opacity: showOverlay ? 1:0, duration: 1});
			this.overlayVisible = showOverlay;
		}
		if(this.currentBackground !== backgroundName) {
			timeline.add(new TimelineMax()
				.fromTo(this.bg2.nativeElement, 
					{
						opacity: 0, 
						'background-image': 'url("assets/dynamic/decors/' + backgroundName + '.jpg',
						transform: 'translate(0, 0)'
					}, 
					{
						opacity: 1, 
						duration: 0.5
					}
				)
				.set(this.bg1.nativeElement,
					{
						'background-image': 'url("assets/dynamic/decors/' + backgroundName + '.jpg',
						transform: 'translate(0, 0)',
						opacity: 0,
					}
				)
				.to(this.bg1.nativeElement, 
					{
						opacity: 1,
						duration: 0.1,
					}
				)
				.to(this.bg2.nativeElement,
					{
						opacity: 0, 
						duration: 0.1
					}
				)
				, 0
			);
			this.currentBackground = backgroundName;
		}
		if(this.travellingTimeline != null)
			timeline.call(() => {console.log("Play Travel Timeline"); this.travellingTimeline.play();})
		return timeline;
	}

	public setBackgroundWithTranslation(backgroundName: string, showOverlay: boolean = false): any {
		const timeline = new TimelineMax();
		if(this.overlayVisible !== showOverlay) {
			//timeline.to(this.bgOverlay.nativeElement, {opacity: showOverlay ? 1:0, duration: 1});
			this.overlayVisible = showOverlay;
		}
		if(this.currentBackground !== backgroundName) {
			timeline.add(new TimelineMax()
				.fromTo(this.bg2.nativeElement, 
					{
						'background-image': 'url("assets/dynamic/decors/' + backgroundName + '.jpg',
						transform: 'translate(0, 0)'
					}, 
					{
						transform: 'translate(-100px, 0)',
						duration: 3
					}
				)
				.fromTo(this.bg2.nativeElement, 
					{
						opacity: 0, 
					}, 
					{
						opacity: 1, 
						duration: 0.5
					},
					0
				)
				.set(this.bg1.nativeElement,
					{
						'background-image': 'url("assets/dynamic/decors/' + backgroundName + '.jpg',
						opacity: 0,
						transform: 'translate(-100px, 0)',
					}
				)
				.to(this.bg1.nativeElement, 
					{
						opacity: 1,
						duration: 0.1,
					})
				.to(this.bg2.nativeElement,
					{
						opacity: 0, 
						duration: 0.1
					}
				)
				, 0
			);
			this.currentBackground = backgroundName;
		}
		if(this.travellingTimeline != null)
		timeline.call(() => {console.log("Play Travel Timeline"); this.travellingTimeline.play();})
		return timeline;
	}

	public setBackgroundOverlay(showOverlay: boolean) {
		const timeline = new TimelineMax();
		if(this.overlayVisible !== showOverlay) {
			//timeline.to(this.bgOverlay.nativeElement, {opacity: showOverlay ? 1:0, duration: 1});
			this.overlayVisible = showOverlay;
		}
		return timeline;
	}

	public hideBackgroundWithFade() {
		const timeline = new TimelineMax();
		//timeline.to(this.bgOverlay.nativeElement, {opacity: showOverlay ? 1:0, duration: 1});
		this.overlayVisible = false;
		if(this.currentBackground !== "") {
			timeline.add(new TimelineMax()
				.to(this.bg1.nativeElement, 
					{
						opacity: 0,
						duration: 0.5,
					}
				)
				, 0
			);
			this.currentBackground = "";
		}
		return timeline;
	}

	public getJaugeValue(jaugeName: string)
	{
		return this.userData.data.dataActions[jaugeName];
	}
}
