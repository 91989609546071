import { Component, OnInit, HostBinding, ElementRef, ViewChild } from '@angular/core';
import { modalAnimation } from 'src/app/animations/menu.animations';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { LogItem, UserData } from 'src/app/model/userdata.interface';
import { UserDataService } from 'src/app/services/user-data.service';
import { InfoScrollService } from 'src/app/shared/ui/info-scroll.service';
import * as $ from 'jquery';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/shared/navigation/navigation.service';
import { WordingsService } from 'src/app/services/wordings.service'


@Component({
	selector: 'app-modal-history',
  templateUrl: './modal-history.component.html',
	styleUrls: ['./modal-history.component.scss'],
	animations: [modalAnimation],
})
export class ModalHistoryComponent implements OnInit {
	
	//@HostBinding('@animations') private animations;
	// @ViewChild("logsElement") logsElement:ElementRef;
	
	logs:LogItem[];

  constructor(
		private modalService:ModalsService,
		private userData:UserDataService,
		private ds:DataService,
		private wordings: WordingsService,
		private navigation:NavigationService,
	) { }

  ngOnInit() {
		this.logs = this.userData.data.logs;
	}
	
	
	
	
	clickClose():void
	{
		this.modalService.close('modal_game');
	}
	
	/* 
	public scroll(delta:number, distance:number):void
	{
		
		let elmt = this.logsElement.nativeElement;
		$(elmt).animate({
			scrollTop: elmt.scrollTop + delta * distance,
		}, 230);
		
	}
	 */
	
}
