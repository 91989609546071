import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, NgZone, Input } from '@angular/core';
import { AnswersComponent } from '../answers/answers.component';
import { TextLineData } from 'src/app/model/callbackinfo.interface';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import { TimelineMax } from 'gsap';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss']
})
export class NextButtonComponent {
  private isVisible: boolean;

  private targetIsVisible: boolean;
  private currentVisibility: boolean = false;
  private blockInput: boolean = true;
  private timer: boolean = false;
  private timerTime: number;

  @Output() nextClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() timerPaused: boolean = false;


  constructor(
    private animations: GameAnimationsService,
    private ngZone: NgZone,
    private wordings: WordingsService,
    private mainElement: ElementRef) { }

  ngAfterViewInit() {
  }

  onClick(): void
  {
    this.blockInput = true;
    this.ngZone.run(() => {
      this.mainElement.nativeElement.style.pointerEvents = 'none';
    });
  }

  updateDisplay(answers: string[], talkers: string[], textLines: TextLineData[])
  {
    if(this.currentVisibility)
    {
      let timeline = new TimelineMax();
      timeline.set(this.mainElement.nativeElement, {'pointer-events': 'none'});
      timeline.to(this.mainElement.nativeElement, {'opacity': 0, duration: 0.5});
      this.animations.pushAnimation("disappear next-button", timeline, 0, 0);
    }
    let targetVisibility = true;
    this.targetIsVisible = true;
    if(answers.length > 0)
    {
      targetVisibility = false;
      this.targetIsVisible = false;
    }
    for(let textLine of textLines)
    {
      if(textLine.parameters.includes('Didascalie'))
      {
        this.targetIsVisible = false;
        targetVisibility = false;
      }
    }
    if(targetVisibility)
    {
      let timeline = new TimelineMax();
      timeline.call(() => {
        this.ngZone.run(() => {
          this.blockInput = true;
          this.currentVisibility = targetVisibility;
          this.mainElement.nativeElement.style.pointerEvents = 'all';
        });
      });
      timeline.to(this.mainElement.nativeElement, {'opacity': 1, duration: 0.2});
      timeline.call(() => {
        this.ngZone.run(() => {
          this.isVisible = this.targetIsVisible;
          if(this.timer)
            this.timerTime = 120;
        });
      });
      this.animations.pushAnimation("appear footer", timeline, 2, 20);
    }
    else
    {
      this.currentVisibility = false;
    }
  }
}
