import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, NgZone, AfterViewInit } from '@angular/core';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import {TimelineMax} from 'gsap';
import { UserData } from 'src/app/model/userdata.interface';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-answers-intro',
  templateUrl: './answers-intro.component.html',
  styleUrls: ['./answers-intro.component.scss']
})
export class AnswersIntroComponent implements AfterViewInit {
	private displayAnswers: string[];
	@ViewChild('mainElement') mainElement:ElementRef;
	@ViewChild('characterIcon') characterIcon: ElementRef;
	@Output() answerClicked: EventEmitter<number> = new EventEmitter<number>();
	private isVisible: boolean;
	private pendingDisplayAnswers: string[];

	constructor(private ngZone: NgZone, private animations : GameAnimationsService, private host: ElementRef, private userData: UserDataService, private wordings: WordingsService) { }

	ngAfterViewInit() {
		const scenarioIndex = this.userData.data.indexScenario;
		const characterName = this.wordings.translate("ui.chap" + scenarioIndex + ".character");
		this.characterIcon.nativeElement.style['background-image'] = 'url(assets/dynamic/persos/icon_' + characterName + '.png)';
		
	}

	showAnswers(answers: string[])
	{
		this.pendingDisplayAnswers = answers;
		if(this.isVisible)
			this.animations.pushAnimation("Disappear answers", this.getDisappearAnimation(), 0, 0);
		if(answers.length > 1&& this.userData.data.indexScenario == 0)
			this.animations.pushAnimation("Appear answers", this.getAppearAnimation(), 2, 15);
		this.isVisible = answers.length > 1 && this.userData.data.indexScenario == 0;
	}


	clickAnswer(index:number):void
	{
		this.answerClicked.emit(index);
	}

	getAppearAnimation(): any
	{
		let timeline = new TimelineMax();
		
		timeline.call(() => {
			this.ngZone.run(() => {
				this.displayAnswers = this.pendingDisplayAnswers;
			})
		});
		timeline.set(this.host.nativeElement, {'pointer-events': 'none'})
		timeline.fromTo(this.host.nativeElement, 
			{
				opacity: 0,
			}, 
			{
				opacity: 1,
				duration: 0.2, 
			}
		);
		timeline.set(this.host.nativeElement, {'pointer-events': 'auto'})
		//console.log(timeline);
		return timeline;
	}

	getDisappearAnimation(): any
	{
		let timeline = new TimelineMax();
		timeline.set(this.host.nativeElement, {'pointer-events': 'none'})

		timeline.fromTo(this.host.nativeElement,
			{
				opacity: 1,
			},
			{
				opacity: 0,
				duration: 0.2,
			}
		);
		return timeline;
	};
}
