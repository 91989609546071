import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


// Handles translations for all text. Texts are present in the Wordings google spreadsheet (https://docs.google.com/spreadsheets/d/1aefFqE-gvUeOcEM56TlBJJ3dpwYX32Qq3_JAjwaLN9E/edit#gid=83232108)
@Injectable({
    providedIn: 'root'
})
export class WordingsService {
    selectedLanguage = "";
    availableLanguages = [];
    displayLanguageMenu = true;

    keys: any[];

    selectLanguage(languageIndex: string): void {
        this.selectedLanguage = languageIndex;
        document.cookie = "selectedLanguage=" + this.selectedLanguage + ";expires=9999-12-31T12:00:00.000Z";
        localStorage.setItem("selectedLanguage", this.selectedLanguage);
        window.location.reload();
    }

    loadWordings(data: any, availableLanguages: any): void {
        //console.log("Loading wordings...");
        this.keys = [];
        var lastLanguageSet = "";
        if (document.cookie.includes("selectedLanguage="))
        {
            lastLanguageSet = document.cookie.match(/selectedLanguage=([a-z]+_[A-Z]+);?/)[1];
        }
        if (availableLanguages === null || availableLanguages.length === 0)
        {
            if (lastLanguageSet === "") {
                console.log("WARNING: availableLanguages is empty. Defaulting to last language set: " + lastLanguageSet);
                availableLanguages = [lastLanguageSet];
            }
            else {
                console.log("WARNING: availableLanguages is empty. Defaulting to French.");
                availableLanguages = ["fr_FR"];
            }
        }
        if (lastLanguageSet !== "")
        {
            this.selectedLanguage = lastLanguageSet;
        }
        else
        {
            var browserLanguage = window.navigator.language.split('-')[0];
            availableLanguages.forEach(lang => {
               if (lang.includes(browserLanguage))
               {
                   this.selectedLanguage = lang;
               } 
            });
            if (this.selectedLanguage === "")
                this.selectedLanguage = availableLanguages[0];
        }
        if (availableLanguages.length === 1)
            this.displayLanguageMenu = false;
        this.availableLanguages = availableLanguages;
        for (const [locale, keys] of Object.entries(data)) {
            if (!availableLanguages.includes(locale))
                continue;
            this.keys[locale] = [];
            for (const [key, translation] of Object.entries(keys))
            {
                this.keys[locale][key] = translation;
            }
        }
        //console.log(this.keys);
        //console.log(this.translate('ui.game.title'));
    }

    translateImage(key:string): string
    {
        if(this.keys === undefined || Object.keys(this.keys).length === 0)
            return "";
        if(this.keys[this.selectedLanguage][key] !== null && this.keys[this.selectedLanguage][key] !== "")
            return 'url(' + window.location.origin + environment.BASE_URL + '/data/translated-sprites/' + this.keys[this.selectedLanguage][key] + ')';
        else return 'url()';
    }

    translateEllipse(key: string): string {
        if(this.keys === undefined || Object.keys(this.keys).length === 0)
            return "";
        if(this.keys[this.selectedLanguage][key])
        {
            var ret= this.keys[this.selectedLanguage][key];
            ret = ret.replace("[Ellipse]", "");
            return ret;
        }
        else return 'Error : Missing key ' + key + ' in wording';
    }

    translate(key: string): string {
        if(this.keys === undefined || Object.keys(this.keys).length === 0)
            return "";
        if(this.keys[this.selectedLanguage][key] !== null && this.keys[this.selectedLanguage][key] !== "")
            return this.keys[this.selectedLanguage][key];
        else return 'Error : Missing key ' + key + ' in wording';
    }
    
    cutAndTranslate(key: string, isDidascalie: boolean): string {
        let translatedKey = this.translate(key);
        let linesData = [];
        let ret = "";
        for(const line of translatedKey.split('|')) {
            linesData.push(this.extractTextParameters(line));
        }
        if (isDidascalie)
        {
			for(const textLine of linesData)
			{
				if(textLine.parameters.includes('Didascalie')){
					while(textLine.text.charAt(0) == ' ') textLine.text = textLine.text.substr(1);
                    ret = textLine.text;
				}
			}
        }
        else {
			for(const textLine of linesData)
			{
				if(!textLine.parameters.includes('Didascalie') && !textLine.parameters.includes('Image')){
                    ret = textLine.text;
				}
			}
        }
        return ret;
    }

    hasKey(key: string): boolean {
        return this.keys[this.selectedLanguage][key] != null;
    }

    setTranslation(key: string, value: string) {
        this.keys[this.selectedLanguage][key] = value;
    }


	extractTextParameters(text: string)
	{
		let result: string[] = [];
		while(text.includes('['))
		{
			const startIndex = text.indexOf("[");
			const endIndex = text.indexOf(']', startIndex);
			let cutStr = '';
			if(startIndex > 0)
				cutStr += text.substr(0, startIndex);
			cutStr += text.substr(endIndex + 1, text.length - endIndex);
			result.push(text.substr(startIndex + 1, endIndex - startIndex - 1))
			text = cutStr;
		}
		return {text: text, parameters: result}; 
	}

    parseInfoLinks(text: string): any[] {
        const regex = /<(.+)>/;//<([\w\s-]+)>/;
        let result = [];
        let toParse = text;
        //console.log(text);
        while (toParse) {
            const parsed = toParse.match(regex);
            //console.log(parsed);
            if (parsed) {
                result.push({ type: 'normal', 'text': toParse.substr(0, parsed['index']) });
                result.push({ type: 'link', 'text': parsed[1] });
                toParse = toParse.substr(parsed['index'] + parsed[0].length);
            }
            else {
                result.push({ type: 'normal', 'text': toParse });
                toParse = null;
            }
        }
        return result;
    }
}