import { Component, OnInit, HostBinding, ViewChild, ElementRef, ApplicationRef, Output, EventEmitter, NgZone, AfterViewInit } from '@angular/core';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss']
})
export class SharePopupComponent implements OnInit {
	@ViewChild('descElmt') descElmt:ElementRef;
	@ViewChild('mainContainer') mainContainer:ElementRef;
	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  public emailString: string;
  public emailBodyString: string;
  public score: number;
  public invalidMailMessageVisible: boolean = false;

  constructor(
	  	private wordings: WordingsService,
      public userData: UserDataService,
      private modalService:ModalsService
	) { }

  ngOnInit(): void {
    this.score = this.userData.getCurrentTotalScore();
    this.emailBodyString = this.wordings.translate('ui.share.emailbody');
    this.emailBodyString = this.emailBodyString.replace("#SCORE#", this.score.toString());
  }

  sendMail(): void {
    this.emailString = this.emailString.replace(";", ",");
    let emailArray = this.emailString.split(',');
    let invalidMail = false;
    if (emailArray.length === 0)
    {
        invalidMail = true;
    }
    emailArray.forEach(element => {
      if (!element.match(/[^@]+@[^@]+/))
      {
        invalidMail = true;
      }
    });
    if (invalidMail)
    {
      alert("Email invalide");
      return;
    }
    var subject = this.wordings.translate('ui.share.emailobject');
    var emailBody = encodeURIComponent(this.emailBodyString);
    window.open('mailto:' + this.emailString.replace(" ", "") + '?subject=' + subject + '&body=' +   emailBody);
    this.continue();
  }

  continue(): void {
    this.modalService.open('game-end');
  }

}
