import { Injectable } from '@angular/core';

declare var gtag:any;
declare var uid: string;

@Injectable()
export class GoogleAnalyticsService {

	constructor() { }
	
	
	public setUid(newUid: string):void
	{
		uid = newUid;
	}
	
	
	public emitEvent(eventCategory: string,
		eventAction: string,
		eventLabel: string = null,
		eventValue: number = null) {
		
		console.log("GA.emitEvent("+eventCategory+", "+eventAction+")");
		if(typeof gtag !== 'undefined')
		{
			gtag('event', eventAction, {
				event_category: eventCategory,
				event_label: eventLabel,
				event_value: eventValue,
			});
		}
		
	}
	
	
	public emitPage(page:string) {
		
		//console.log("GA.emitPage("+page+")");
		if(typeof gtag !== 'undefined')
		{
			gtag('event', 'page_view', {
				page_title: page,
				page_path: page,
				//send_to: '<GA_MEASUREMENT_ID>'
			});
		}
	}
}
