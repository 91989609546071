import { NavigationService } from './../../services/navigation.service';
import { Component, OnInit, HostBinding, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { menuAnimation } from 'src/app/animations/menu.animations';
import { BroadcasterService } from 'src/app/shared/events/broadcaster.service';
import { UserData } from 'src/app/model/userdata.interface';
import { UserDataService } from 'src/app/services/user-data.service';
import { RouterEvent } from '@angular/router';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { environment } from 'src/environments/environment';
import { FullscreenService } from 'src/app/shared/utils/fullscreen.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { GameService } from 'src/app/services/game.service';
import { TimelineMax } from 'gsap';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, AfterViewInit {
	@ViewChild('mainContainer') mainContainer: ElementRef;
	@ViewChild('menuContainer') menuContainer: ElementRef;
	@ViewChild('credits') credits: ElementRef;

	sound:boolean;
	fs:boolean;
	mode:string;
	showRestart_chapter: boolean;
	showRestart_game:boolean;
	showConfirmRestart: boolean;
	creditsVisible: boolean = false;
	legalVisible: boolean = false;
	
  constructor(
		private storage:StorageService,
		private modalService:ModalsService,
		private soundPlayer:SoundPlayerService,
		public ds:DataService,
		private wordings: WordingsService,
		private api:ApiService,
		private broadcast:BroadcasterService,
		private navigation:NavigationService,
		private userData:UserDataService,
		private routerEvent:RouterEventService,
		private fullscreen:FullscreenService,
		private gameService: GameService,
		private ngZone: NgZone
		) { }
		
		
		

  ngOnInit() {
		
		let _this = this;
		this.sound = this.storage.getBoolean('sound', true);
		this.broadcast.addListener('CLICK_RESTART_GAME', this.confirmRestartGame.bind(this));
		this.broadcast.addListener('CLICK_RESTART_CHAPTER', this.confirmRestartChapter.bind(this));
		
		/* 
		// this.showRestart = this.routerEvent.isRouteActive('game');
		this.showRestart = true;
		*/
		this.showRestart_chapter = this.routerEvent.isRouteActive('game');
		this.showRestart_game = true;
		this.showConfirmRestart = false;
		
		
		//todo :
		//set fs depending on fullscreen
		this.fs = this.fullscreen.isFullscreen();
	}

	ngAfterViewInit() {
		const timeline = new TimelineMax();
		timeline.fromTo(this.mainContainer.nativeElement, {opacity: 0}, {opacity: 1, duration: 0.5});
	}
	
	
	ngOnDestroy():void
	{
		this.broadcast.removeListener('CLICK_RESTART');
	}
	
	confirmRestartGame():void
	{
		this.modalService.close('modal');
		
		this.userData.data.ngGameover = 0;
		this.userData.data.scores = [];
		this.userData.save();
		
		setTimeout(() => {
			this.modalService.close('modal_menu')
		}, 300);
		
		setTimeout(() => {
			if(this.mode == 'home') this.modalService.open('home');
			else this.navigation.restart('restart_game');
		}, 600);
		
		this.userData.data.ngGameover = 0;
		this.userData.data.scores = [];
		this.userData.save();
	}

	confirmRestartChapter():void
	{
		this.modalService.close('modal');
		
		setTimeout(() => {
			this.modalService.close('modal_menu')
		}, 300);
		
		setTimeout(() => {
			if(this.mode == 'home') this.modalService.open('home');
			else this.navigation.restart('restart_chapter');
		}, 600);
		
	}

	backToMenu()
	{
		this.showConfirmRestart = false;
	}
	
	clickLink(id:string):void
	{
		if(id == 'sound'){
			this.sound = !this.sound;
			this.storage.setBoolean('sound', this.sound);
			
			if(this.sound){
				this.soundPlayer.enable(true);
			
				if(this.routerEvent.isRouteActive('game')){
					
					/*this.soundPlayer.play('assets/sounds/gametheme.mp3', {
						volume: 0.2,
						loop: true,
						forceStart: false,
						group: 'music',
					});*/
					
				}
				
			}
			else this.soundPlayer.disable();
			
		}
		else if(id == 'fs'){
			this.fs = !this.fs;
			if(this.fs) this.fullscreen.openFullscreen();
			else this.fullscreen.closeFullscreen();
		}
		
		if(id=='credits'){
		}
		else if(id=='mentions') {
		}
		else if(id=='infos'){
			
		}
		else if(id=='home'){
			
			this.mode = 'home';
			this.modalService.open('confirmquit', 'modal');
		}
		else if(id=='restart_chapter'){
			
			this.mode = 'restart_chapter';
			this.showConfirmRestart = true;
			//this.soundPlayer.play('assets/sounds/orchestral_hit.mp3');
			
		}
		else if(id=='restart_game'){
			
			this.mode = 'restart_game';
			this.showConfirmRestart = true;
			//this.soundPlayer.play('assets/sounds/orchestral_hit.mp3');
			
		}
		else if(id=='tuto'){
			this.ds.opentutoMenu = true;
			this.mode = 'tuto';
			this.modalService.close('modal_menu')
			.then(() => {
				this.modalService.open('tuto', 'modal');
			});
		}
	}

	openLeaderboard()
	{
		this.modalService.open('leaderboard_popup', 'modal_menu');
		this.api.getLeaderboard(this.ds.uid).subscribe(resp => {
			this.ds.leaderboard_global = resp.data.leaderboard;
			// if(environment.debug) this.ds.leaderboard_global[1].isHero = true;
			
			this.ds.leaderboard = this.ds.leaderboard_global;
				
		});
	}
	
	openLegal()
	{
		this.modalService.open('legal_popup', 'modal_menu');
	}

	openCredits() 
	{
		this.modalService.open('credits_popup', 'modal_menu');
	}

	openTuto() 
	{
		this.modalService.open('tuto_popup', 'modal_menu');
	}
	
	clickClose():void
	{
		this.modalService.close('modal_menu')
		// this.modalService.openPrevModal();
	}

	closeCredits(): void
	{
		new TimelineMax()
		.call(() => this.ngZone.run(() => this.creditsVisible = false))
		.to(this.menuContainer.nativeElement, {opacity: 1, duration: 0.5});
	}

	closeLegal(): void
	{
		new TimelineMax()
		.call(() => this.ngZone.run(() => this.legalVisible = false))
		.to(this.menuContainer.nativeElement, {opacity: 1, duration: 0.5});
	}
}
