import { routes } from './app.routes';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PageGameComponent } from './pages/page-game/page-game.component';
import { LandingComponent } from './pages/landing/landing.component';
import { HomeComponent } from './pages/home/home.component';
import { ChapterStartComponent } from './pages/chapter-start/chapter-start.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ConfirmquitComponent } from './pages/confirmquit/confirmquit.component';
import { ModalHistoryComponent } from './modals/modal-history/modal-history.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ChapterEndComponent } from './pages/chapter-end/chapter-end.component';
import { CreditsComponent } from './pages/credits/credits.component';
import { ScoreSendComponent } from './pages/score-send/score-send.component';
import { EndGameComponent } from './pages/end-game/end-game.component';

import { Gameover2Component } from './pages/gameover2/gameover2.component';
import { SoundClickDirective } from './shared/sound/sound-click.directive';
import { HiddenDirective } from './shared/directives/hidden.directive';
import { VisibleDirective } from './shared/directives/visible.directive';
import { TutoComponent } from './pages/tuto/tuto.component';
import { BookComponent } from './components/book/book.component';
import { FloorPipe } from './floor.pipe';
import { InfoPopupComponent, InfoPopupElement } from './pages/page-game/widgets/info-popup/info-popup.component';
import { BubbleComponent } from './pages/page-game/widgets/bubble/bubble.component';
import { TalkersComponent } from './pages/page-game/widgets/talkers/talkers.component';
import { NotebookComponent } from './pages/page-game/widgets/notebook/notebook.component';
import { EllipseComponent } from './pages/page-game/widgets/ellipse/ellipse.component';
import { AnswersComponent } from './pages/page-game/widgets/answers/answers.component';
import { LanguageSelectComponent } from './pages/language-select/language-select.component';
import { LegalComponent } from './pages/legal/legal.component';
import { ThankComponent } from './pages/thank/thank.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { QuizComponent } from './pages/quiz/quiz.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { JaugeComponent } from './pages/page-game/widgets/jauge/jauge.component';
import { VhsButtonComponent } from './pages/menu/vhs-button/vhs-button.component';
import { DidascalieComponent } from './pages/page-game/widgets/didascalie/didascalie.component';
import { BubbleSelfComponent } from './pages/page-game/widgets/bubble-self/bubble-self.component';
import { NextButtonComponent } from './pages/page-game/widgets/next-button/next-button.component';
import { JaugeBigComponent } from './pages/page-game/widgets/jauge-big/jauge-big.component';
import { AnswersIntroComponent } from './pages/page-game/widgets/answers-intro/answers-intro.component';
import { MesureImpactComponent } from './pages/mesure-impact/mesure-impact.component';
import { ScoreboardComponent } from './pages/score-board/score-board.component';
import { QuizLeaderboardComponent } from './pages/quiz-leaderboard/quiz-leaderboard.component';
import { MenuLeaderboardComponent } from './pages/menu-leaderboard/menu-leaderboard.component';
import { SharePopupComponent } from './pages/quiz-leaderboard/widgets/share-popup/share-popup.component';
import { CryptoJS } from "node_modules/crypto-js/crypto-js.js";
import { FormLineComponent } from './form-line/form-line.component';

@NgModule({
  declarations: [
    AppComponent,
    PageGameComponent,
    LandingComponent,
    HomeComponent,
    ChapterStartComponent,
    MenuComponent,
    ConfirmquitComponent,
    ModalHistoryComponent,
    CreateAccountComponent,
    ChapterEndComponent,
    CreditsComponent,
    ScoreSendComponent,
    EndGameComponent,
    JaugeComponent,
    DidascalieComponent,
    Gameover2Component,
    SoundClickDirective,
    HiddenDirective,
    VisibleDirective,
    TutoComponent,
    BookComponent,
    FloorPipe,
    InfoPopupComponent,
    InfoPopupElement,
    BubbleComponent,
    TalkersComponent,
    JaugeBigComponent,
    NotebookComponent,
    EllipseComponent,
    AnswersComponent,
    AnswersIntroComponent,
    NextButtonComponent,
    BubbleSelfComponent,
    LanguageSelectComponent,
    LegalComponent,
    ThankComponent,
    QuizComponent,
    LeaderboardComponent,
    VhsButtonComponent,
    MesureImpactComponent,
    ScoreboardComponent,
    QuizLeaderboardComponent,
    MenuLeaderboardComponent,
    SharePopupComponent,
    FormLineComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule.forRoot(
      [
        { target: '\n', replace: '<br />' },
      ]
    ),
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
