import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { DataService } from 'src/app/services/data.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserDataService} from 'src/app/services/user-data.service';

@Component({
  selector: 'app-menu-leaderboard',
  templateUrl: './menu-leaderboard.component.html',
	styleUrls: ['./menu-leaderboard.component.scss'],
})
export class MenuLeaderboardComponent implements OnInit {
	private score: number;
	
  constructor(
	  	private wordings: WordingsService,
		private modalService:ModalsService,
		private routerEvent:RouterEventService,
		public ds:DataService,
		public userData: UserDataService,
	) { }

  	ngOnInit() {
		this.score = this.userData.getCurrentTotalScore();
  	}
  
	valid():void
	{
		this.modalService.open('menu', 'modal_menu');
	}
  
}
