import { NavigationService } from './../../services/navigation.service';
import { DataService } from './../../services/data.service';
import { environment } from './../../../environments/environment';
import { GameService } from './../../services/game.service';
import { GameNode } from '../../model/gamenode.interface';
import { Component, HostListener, OnInit, ViewChildren, ElementRef, QueryList, ViewChild, Renderer2, ApplicationRef, NgZone, HostBinding, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CallbackInfo } from 'src/app/model/callbackinfo.interface';
import { CallbackType } from 'src/app/model/types';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { InfoScrollService } from 'src/app/shared/ui/info-scroll.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { GoogleAnalyticsService } from 'src/app/shared/api/google-analytics.service';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';
import { PlatformService } from 'src/app/shared/utils/platform.service';
import { NavigationService as NavigationServiceShared } from './../../shared/navigation/navigation.service';
import { ScrollBlockComponent } from 'src/app/shared/ui/scroll-block/scroll-block.component';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { AppAnimateService } from 'src/app/services/app.animate.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { BubbleComponent } from './widgets/bubble/bubble.component';
import { TalkersComponent } from './widgets/talkers/talkers.component';
import { NotebookComponent } from './widgets/notebook/notebook.component';
import { JaugeComponent } from './widgets/jauge/jauge.component';
import { EllipseComponent } from './widgets/ellipse/ellipse.component';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import { TimelineMax, Power1, Power2 } from 'gsap';
import { AnswersComponent } from './widgets/answers/answers.component';
import { BubbleSelfComponent } from './widgets/bubble-self/bubble-self.component';
import { DidascalieComponent } from './widgets/didascalie/didascalie.component';
import { NextButtonComponent } from './widgets/next-button/next-button.component';
import { AnswersIntroComponent } from './widgets/answers-intro/answers-intro.component';
import { Talker } from 'src/app/pages/page-game/widgets/talkers/talkers.component';

// Page game is the main component of the dialogue/visual novel screen. It handles the coordination of animations via gsap animation timelines

interface JaugeData
{
	jaugeId:       string,
	targetValue:   number,
	displayValue:  number,
	initialized:   boolean,
	colorRatio:	   number,
}

@Component({
	selector: 'app-page-game',
	templateUrl: './page-game.component.html',
	styleUrls: ['./page-game.component.scss'],
})
export class PageGameComponent implements OnInit, AfterViewInit {
	@ViewChild('bubbleSelf') bubbleSelf: BubbleSelfComponent;
	@ViewChild('nextButton') nextButton: NextButtonComponent;
	@ViewChild('didascalie') didascalie: DidascalieComponent;
	@ViewChild('talkerContent') talkerContentComponent: ScrollBlockComponent;
	@ViewChild('bg') bgElmt: ElementRef;
	@ViewChild('talkers') talkersContent: TalkersComponent;
	@ViewChild('bubble') bubble: BubbleComponent;
	@ViewChild('notebook') notebook: NotebookComponent;
	@ViewChild('jauge1') jauge1: JaugeComponent;
	@ViewChild('ellipse') ellipse: EllipseComponent;
	@ViewChild('container') container: ElementRef;
	@ViewChild('infoIcon') infoIcon: ElementRef;
	@ViewChild('foregroundImage') foregroundImage: ElementRef;
	@ViewChild('answers') answers: AnswersComponent;
	@ViewChild('answersIntro') answersIntro: AnswersIntroComponent;
	@ViewChild('progression') progression: ElementRef;

	styleBG: any = { 'background-image': '', };
	node: GameNode;
	level: number;
	blockDisplay: string;
	infoScroll: InfoScrollService;
	indexScenario: number;
	talkerName: string;
	talkerColor: string;
	heroName: string;
	heroColor: string;
	styleFooter: any;
	indexDebug: number;
	timer: boolean = false;
	timerTime: number = 90;

	type: string;

	styleScroll: any;
	styleScrollGrad: any;

	changeColorA: string;
	changeColorB: string;

	deltaChangeA: number;
	deltaChangeB: number;

	showNotebook: boolean = false;

	showInfo: boolean = false;
	showImage: boolean = false;
	imageStyle: any;

	pendingTextLines;

	notebookLeftPage: string;
	notebookRightPage: string;
	ellipseText: string;
	visibleTalker: string;

	private targetBackground: string;
	private jauges: JaugeData[];
	private progressionCursorClass: string[] = ['progression-cursor', 'progression-cursor0'];
	private twoSteps: boolean;
	private currentInfo: CallbackInfo;

	private selectedAnswer = -1;

	constructor(
		private gameService: GameService,
		private ds: DataService,
		private modalService: ModalsService,
		private userData: UserDataService,
		private navigation: NavigationService,
		private ga: GoogleAnalyticsService,
		private platform: PlatformService,
		private navShared: NavigationServiceShared,
		private routerEvent: RouterEventService,
		private gameAnimations: GameAnimationsService,
		private ngZone: NgZone,
		private cd: ChangeDetectorRef,
		private wordings: WordingsService,

	) { }

	ngOnInit() {
		this.jauges = [
			{
				jaugeId: "jauge1",
				targetValue: 0,
				displayValue: 0,
				colorRatio: 0,
				initialized: false,
			}
		];
		this.indexScenario = this.userData.data.indexScenario;

		this.indexDebug = 0;

		this.gameService.callback = this.callback.bind(this);
		this.gameService.chapterEndCallback = this.chapterEnd.bind(this);
		

		//console.log(this.ds.scenarios);
		//console.log(this.indexScenario);

		if (this.platform.isFirefox()) {
			this.styleScroll = { 'overflow-y': 'scroll', 'scrollbar-width':'none' };
		}


		this.styleScrollGrad = {
			['-webkit-mask-image']: '-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(0, 0, 0)), color-stop(0.88, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))'
		};
	}

	ngAfterViewInit() {
		let nodeId: string = this.userData.data.nodeId;
		if (!nodeId) {
			for (let node in this.ds.scenarios[this.indexScenario].graph) {
				if (this.ds.scenarios[this.indexScenario].graph[node]['type'] == "Start") {
					nodeId = node;
					break;
				}
			}
		}
		else // Only after a reload, not on chapter start
		{
			if(this.userData.data.dataActions !== null && this.userData.data.dataActions['bg'] != undefined)
				this.gameAnimations.pushAnimation("change background", this.gameService.setBackground(this.userData.data.dataActions['bg'], false), 1, 5);
		}
		this.gameService.startChapter(JSON.parse(JSON.stringify(this.ds.scenarios[this.indexScenario].graph)), nodeId);
		
		new TimelineMax().fromTo(this.container.nativeElement, { opacity: 0 }, { opacity: 1, duration: 1, });
		if(this.userData.data.dataActions['progression'] == undefined)
			this.userData.data.dataActions['progression'] = 0;
		if(this.progression != null)
			this.progression.nativeElement.style.height = Math.floor(this.userData.data.dataActions['progression']) + '%';
		
	}


	ngAfterInit() {

	}

	array(n: number) {
		let result = Array(n);
		for (let i = 1; i <= n; i++)
			result[i - 1] = i;
		return result;
	}

	hideInfo() {
		this.showInfo = false;
	}

	openInfo() {
		this.showInfo = true;
	}

	mustShowTalkers(info: CallbackInfo): boolean {
		for (const textLine of info.textLines) {
			if (textLine.parameters.includes("Bulle2"))
				return false;
		}
		return true;
	}

	chapterEnd() {
		if(this.userData.data.indexScenario > 0)
		{
			if(this.userData.data.indexScenario > 0 && this.userData.data.indexScenario < this.ds.scenarios.length - 1)
    			this.userData.applyChapterScore();
			new TimelineMax().to(this.container.nativeElement, { opacity: 0, duration: 1 })
				.call(() => {
					let indexScenario:number = this.userData.data.indexScenario;
					if(indexScenario < this.ds.scenarios.length - 1) // last chapter is outro, so quiz instead of chapter end
					{
						this.modalService.open('chapter-end');
					}
					else
					{
						this.userData.incrChapter();
						this.modalService.open('quiz');
					}
			});
		}
		else if(this.userData.data.indexScenario == 0)
		{
			this.navigation.showTuto();
		}
	}

	gameOver() {
		new TimelineMax().to(this.container.nativeElement, { opacity: 0, duration: 1 })
			.call(() => {
				// this.userData.resetChapter();

				this.navigation.gameOver();
			});
	}

	// Called at every scenario node by the game service
	callback(info: CallbackInfo, node: GameNode, level: number): void {
		this.currentInfo = info;

		// Notify google analytics
		this.ga.emitEvent('alive', 'alive');
		this.ga.emitEvent('scenario', info.voiceFile);
		
		// Update progression cursor, and set the progression value in the user data if not set yet
		if(this.userData.data.dataActions['progression'] == undefined)
			this.userData.data.dataActions['progression'] = 0;
		this.progressionCursorClass[1] = 'progression-cursor' + this.userData.data.dataActions['progression'];
		//console.log('::callback '+(info && info.type));
		//console.log(info);
		//console.log(this.userData.data.dataActions);
		//console.log(this.userData.data.chapterStartData);
		this.node = node;
		this.level = level;

		this.showNotebook = false;//info.parameters != null && info.parameters.includes('Carnet');	
		
		// Should be deprecated, there is no info panel in this game
		if (this.userData.data.dataActions['forceinfo'] > 0) {
			this.showInfo = true;
			this.userData.data.dataActions['forceInfo'] = 0;
		}

		//keep lower than 100
		//scroll infos
		// Force the talker bubble to scroll to top
		if (this.talkerContentComponent) {
			setTimeout(() => {
				this.talkerContentComponent.setScrollTop(0);
			}, 0);
		}

		this.bubble.hideText();

		if (info.background) {
			console.log(info.background);
			const extract = this.gameService.extractTextParameters(info.background);
			console.log(extract);
			let timeline;
			if(extract.parameters.includes("Travelling"))
				this.gameService.startTravelling(extract.arguments[0], extract.arguments[1], extract.arguments[2], extract.arguments[3], extract.arguments[4], extract.arguments[5], extract.arguments[6]);
			else this.gameService.stopTravelling();
			if(extract.parameters.includes("Slide"))
				timeline = this.gameService.setBackgroundWithTranslation(extract.text, false);
			if(extract.parameters.includes("Fade"))
				timeline = this.gameService.setBackgroundWithFade(extract.text, false);
			else
				timeline = this.gameService.setBackground(extract.text, false);

			console.log("SET BACKGROUND " + info.background)
			console.log(extract);
			
			if(this.userData.data.dataActions === null)
				this.userData.data.dataActions = {'bg': info.background};
			else this.userData.data.dataActions['bg'] = info.background;
			//console.log(this.userData.data.dataActions);
			this.gameAnimations.pushAnimation("change bg", timeline, 1, 0);
			this.talkersContent.setTalkers([]);
			this.targetBackground = info.background;
			
			//this.gameAnimations.startUpdateAnimation();
			//save data
			//this.userData.data.dataActions = this.dataActions;
			this.userData.data.nodeId = info.nodeId;
			this.userData.save();
			this.cd.detectChanges();
			return;
		}
		let allParameters: string[] = [];
		for (const textLine of info.textLines) {
			for (const param of textLine.parameters)
				allParameters.push(param);
		}
		let talkers: Talker[] = [];
		for(let i=0; i<info.talkers.length; i++)
		{
			const split = info.talkers[i].split("_");
			talkers.push({name: split[0], emote: split[1]});
		}
		this.twoSteps = false;
		if(info.answers.length > 0 && allParameters.includes('Didascalie'))
		{
			this.twoSteps = true;
		}

		if(this.userData.data.indexScenario > 0)
		{
			let mainTimeline: TimelineMax = new TimelineMax();
			let playJaugesTimeline = false;
			
			let jaugesTimeline: TimelineMax = new TimelineMax();
			for(let jauge of this.jauges)
			{
				if(jauge.targetValue != this.userData.data.dataActions[jauge.jaugeId])
				{
					if(this.userData.data.dataActions[jauge.jaugeId] > 100)
						this.userData.data.dataActions[jauge.jaugeId] = 100;
					if(this.userData.data.dataActions[jauge.jaugeId] < 0)
						this.userData.data.dataActions[jauge.jaugeId] = 0;
					jauge.targetValue = this.userData.data.dataActions[jauge.jaugeId];
					
					if(jauge.initialized)
					{
						let targetColorRatio = -1;
						if(jauge.targetValue >= jauge.displayValue)
							targetColorRatio = 1;
						let valueDiff = Math.abs(jauge.targetValue - jauge.displayValue);
						for(let i=0; i<2; i++)
						{
							jaugesTimeline.to(jauge, {
								colorRatio: targetColorRatio,
								ease: Power1.easeInOut,
								duration: 0.03,
								onUpdate: () => {
									if(this.jauge1 != undefined)
										this.jauge1.setValue(jauge.displayValue, jauge.colorRatio, 1-Math.abs(jauge.colorRatio)/2);
								}
							}, '+=0.1');
							jaugesTimeline.to(jauge, {
								colorRatio: 0,
								ease: Power1.easeInOut,
								duration: 0.03,
								onUpdate: () => {
									if(this.jauge1 != undefined)
										this.jauge1.setValue(jauge.displayValue, jauge.colorRatio, 1-Math.abs(jauge.colorRatio)/2);
								}
							}, '+=0.1');
						}
						jaugesTimeline.to(jauge, {
							colorRatio: targetColorRatio,
							ease: Power1.easeInOut,
							duration: 0.1,
							onUpdate: () => {
								if(this.jauge1 != undefined)
									this.jauge1.setValue(jauge.displayValue, jauge.colorRatio, 1);
							}
						},
						'+=0.1');
						jaugesTimeline.to(jauge, {
							displayValue: Math.max(0, jauge.targetValue),
							duration: valueDiff/15,
							ease: Power2.easeInOut,
							onUpdate: () => {
								if(this.jauge1 != undefined)
									this.jauge1.setValue(jauge.displayValue, jauge.colorRatio, 1);
							}
						}, '-=0.1');
						jaugesTimeline.to(jauge, {
							duration: 0.5,
							colorRatio: 0,
							onUpdate: () => {
								if(this.jauge1 != undefined)
									this.jauge1.setValue(jauge.displayValue, jauge.colorRatio);
							}
						});
						if(jauge.targetValue <= 0)
						{
							jaugesTimeline.call(() => {
								this.ngZone.run(() => {
									this.gameOver();
								})
							});
							return;
						}
						playJaugesTimeline = true;
						mainTimeline.add(jaugesTimeline, 0);
					}
					else
					{
						jauge.displayValue = jauge.targetValue;
						if(this.jauge1 != null)
    						this.jauge1.setValue(jauge.displayValue, 0);
						jauge.initialized = true;
					}
				}
			}
			if(this.selectedAnswer >= 0)
			{
				let targetColor: string = null;
				if(this.jauges[0].targetValue > this.jauges[0].displayValue)
					targetColor = '#88992a';
				else if(this.jauges[0].targetValue < this.jauges[0].displayValue)
						targetColor = '#e5384a';
				mainTimeline.add(this.answers.answerHilightAnimation(this.selectedAnswer, targetColor), 0);
				playJaugesTimeline = true;
				this.selectedAnswer = -1;
			}
			if(playJaugesTimeline)
				this.gameAnimations.pushAnimation("Jauge Update", mainTimeline, -1, 0);
		}

		this.talkersContent.setTalkers(talkers);
		if(!this.twoSteps)
		{
			this.answers.showAnswers(info.answers);
			this.answersIntro.showAnswers(info.answers);
			this.bubbleSelf.updateDisplay(info.answers, info.talkers, info.textLines);
		}
		else
		{
			this.answers.showAnswers([]);
			this.answersIntro.showAnswers([]);
			this.bubbleSelf.updateDisplay([], info.talkers, info.textLines);
		}

		if (!allParameters.includes('Ellipse'))
			this.ellipse.hideEllipse();
		this.didascalie.updateDisplay(info.talkers, info.textLines, info.voiceFile);
		this.nextButton.updateDisplay(info.answers, info.talkers, info.textLines);

		if (allParameters.includes('Carnet'))
			this.notebook.showNotebook(info.textLines);
		else {
			this.notebook.hideNotebook();
			for (const textLine of info.textLines) {
				if(textLine.parameters.includes("Image"))
				{
					this.imageStyle = {"background-image": "url(assets/dynamic/other/" + textLine.text + ")"};
				}
				if (textLine.parameters.includes('Didascalie')) {
					this.blockDisplay = CallbackType.Didascalie;
				}
				if (textLine.parameters.includes('Ellipse')) {
					this.ellipse.showEllipse(info.voiceFile);
				}
				else {
					this.timerTime = 120;

					this.timer = textLine.parameters.includes("Timer");
					
					//anim bulle
					if (textLine.parameters.includes('Carnet')) {

					}
					else if (!textLine.parameters.includes('Didascalie') && !textLine.parameters.includes('Image')) {
						this.bubble.showText(info.talkers[0], textLine.text, textLine.parameters, info.voiceFile);
						
					}
					this.blockDisplay = CallbackType.Dialog;
					this.showNotebook = textLine.parameters != null && textLine.parameters.includes('Carnet');
				}
			}
		}
		if (allParameters.includes('ForceInfo'))
			this.openInfo();

		if(allParameters.includes('Image') != this.showImage)
		{
			this.showImage = allParameters.includes("Image");
			let timeline = new TimelineMax();
			timeline.to(this.foregroundImage.nativeElement, {alpha: this.showImage ? 1 : 0, duration: 0.3});
			this.gameAnimations.pushAnimation("Show Image", timeline, 1, 1);
		}
		
		//save data
		//this.userData.data.dataActions = this.dataActions;
		this.userData.data.nodeId = info.nodeId;
		this.userData.save();
		this.cd.detectChanges();

		if(allParameters.includes('Checkpoint'))
		{
			this.userData.setCheckpoint();
		}
		if(this.userData.data.dataActions['progression'] == undefined)
			this.userData.data.dataActions['progression'] = 0;
		if(this.progression != null)
		    this.progression.nativeElement.style.height = Math.floor(this.userData.data.dataActions['progression']) + '%';
	}

	@HostListener('click', ['$event'])
	clickEvent(event: KeyboardEvent) {
		// //console.log('click game');
		if (this.routerEvent.isRouteActive('history')) {
			this.navShared.close('modal_game');
		}
	}

	@HostListener('window:keyup', ['$event'])
	keyEvent(event: KeyboardEvent) {

		let selectedIndex: number = event.keyCode - 96;
		if (selectedIndex >= 0 && selectedIndex <= 4) {

			this.follow(selectedIndex);
		}
	}

	showSecondStep(): void {
		
		this.twoSteps = false;

		if(!this.twoSteps)
		{
			this.answers.showAnswers(this.currentInfo.answers);
			this.answersIntro.showAnswers(this.currentInfo.answers);
			this.bubbleSelf.updateDisplay(this.currentInfo.answers, this.currentInfo.talkers, []);
		}
		else
		{
			this.answers.showAnswers([]);
			this.answersIntro.showAnswers([]);
		}

		this.didascalie.updateDisplay(this.currentInfo.talkers, [], this.currentInfo.voiceFile);
		this.nextButton.updateDisplay(this.currentInfo.answers, this.currentInfo.talkers, []);
		this.gameAnimations.startUpdateAnimation();
	}

	answerClicked(index: number): void {
		this.selectedAnswer = index;
		this.ga.emitEvent("scenario", this.currentInfo.voiceFile + ".answer" + index)
		this.follow(index);
	}

	clickNext(): void {
		if(this.twoSteps)
		{
			this.showSecondStep();
		}
		else
		{
			this.follow(0);
		}
	}

	nextButtonClicked(): void {
		this.nextButton.onClick();
		this.clickNext();
	}


	follow(selectedIndex: number = -1): void {
		this.userData.data.dataActions["lastJauge1"] = this.userData.data.dataActions["jauge1"];
		if (selectedIndex != -1) {
			let heroKeyName: string;
			if (this.indexScenario == 1) heroKeyName = 'jim';
			else if (this.indexScenario == 2) heroKeyName = 'bernard';
			else if (this.indexScenario == 3) heroKeyName = 'alice';
		}
		
		//console.log("Follow" + selectedIndex);
		this.gameService.followNodes(this.node, this.level, selectedIndex);
	}


	clickFooter(): void {
		if (this.blockDisplay == 'Didascalie') {
			this.clickNext();
		}
	}

	clickLog(): void {
		//console.log('click log');
		setTimeout(() => {
			this.modalService.open('history', 'modal_game');
		}, 50);

	}


	getTime(value: number): number {
		if (environment.skipTimeout) return 0.01;
		else return value;
	}

	mustShowHeader(): boolean {
		return this.userData.data.indexScenario > 0 && this.userData.data.indexScenario < this.ds.scenarios.length - 1;
	}
}
